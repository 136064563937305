<template>
  <v-container class="fill-height justify-center">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row justify="center">
      <v-col md="8" lg="6">
        <v-card class="elevation-4">
          <div class="pa-7 pa-sm-12 text-center">
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              The Billfish Foundation Tag & Release Application
            </h2>
            <img src="@/assets/billfish-logo-new.png" alt="Billfish" />
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              Recover Password
            </h2>
            <h6 class="subtitle-1 mb-5">
              Please enter a valid email to recover your password
            </h6>
            <v-form ref="form" @submit="send">
              <v-text-field
                label="Email"
                v-model="email"
                :rules="rules"
                outlined
                clearable
              />
            </v-form>
            <v-btn color="primary" @click="send">
              Send recover email
            </v-btn>
            <v-spacer />
            <v-btn class="mt-5" depressed @click="goBack">
              Go back
            </v-btn>
            <v-row justify="end" align="center" class="mt-5">
              <v-col cols="8" align-self="center" class="text-start">
                <span>
                  &copy;
                  <span v-text="` - The Billfish Foundation - ${year}`" />
                </span>
              </v-col>
              <v-spacer />
              <v-col>
                <v-list-item to="/privacy" target="_blank" dense>
                  <v-list-item-content v-text="'Privacy'" />
                </v-list-item>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { notifyMessage } from '../../helpers/handler';
export default {
  name: 'forgot-password',
  data: () => ({
    email: null,
    rules: [
      v => !!v || 'An email is required',
      v => /.+@.+\..+/.test(v) || 'The value entered is not a valid email'
    ],
    overlay: false
  }),
  computed: {
    year() {
      return new Date().getFullYear();
    }
  },
  methods: {
    ...mapActions('users', ['forgotPwd']),
    async send() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        const { data } = (await this.forgotPwd({ email: this.email })) || {};
        notifyMessage(
          data?.success
            ? 'Sent! Please check your mail'
            : 'Something went wrong'
        );
        this.overlay = false;
      }
    },
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
img {
  background-color: dodgerblue;
  border-radius: 10px;
}
</style>
